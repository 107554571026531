import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "student" }
const _hoisted_2 = { class: "student__wrap" }
const _hoisted_3 = { class: "instructor__mobile-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "student__mobile-container" }
const _hoisted_6 = {
  key: 0,
  class: "student__title"
}
const _hoisted_7 = { class: "student__value" }
const _hoisted_8 = {
  key: 1,
  class: "student__title"
}
const _hoisted_9 = { class: "student__value" }
const _hoisted_10 = {
  key: 2,
  class: "student__title"
}
const _hoisted_11 = {
  key: 3,
  class: "student__value"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 4,
  class: "student__title"
}
const _hoisted_14 = {
  key: 5,
  class: "student__value"
}
const _hoisted_15 = {
  key: 6,
  class: "student__title"
}
const _hoisted_16 = {
  key: 7,
  class: "student__value"
}
const _hoisted_17 = {
  key: 8,
  class: "student__title"
}
const _hoisted_18 = { class: "main-table" }
const _hoisted_19 = { class: "student__info-block" }
const _hoisted_20 = { class: "student__value" }
const _hoisted_21 = { class: "student__value" }
const _hoisted_22 = { class: "student__value" }
const _hoisted_23 = { class: "student__controls" }
const _hoisted_24 = { class: "main-table__tabs" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "main-table__active-status" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = {
  key: 1,
  class: "main-table__mobile-wrap"
}
const _hoisted_30 = { class: "main-table__head" }
const _hoisted_31 = { class: "main-table__head-id" }
const _hoisted_32 = { class: "main-table__sort-block" }
const _hoisted_33 = { class: "main-table__head-big-tr" }
const _hoisted_34 = { class: "main-table__sort-block" }
const _hoisted_35 = { class: "main-table__head-big-tr" }
const _hoisted_36 = { class: "main-table__sort-block" }
const _hoisted_37 = { class: "main-table__head-big-tr" }
const _hoisted_38 = { class: "main-table__sort-block" }
const _hoisted_39 = { class: "main-table__head-big-tr" }
const _hoisted_40 = { class: "main-table__sort-block" }
const _hoisted_41 = { class: "main-table__head-big-tr" }
const _hoisted_42 = { class: "main-table__sort-block" }
const _hoisted_43 = { class: "main-table__head-big-tr" }
const _hoisted_44 = { class: "main-table__sort-block" }
const _hoisted_45 = { class: "main-table__body-id" }
const _hoisted_46 = { class: "main-table__body-big-tr" }
const _hoisted_47 = { class: "main-table__body-big-tr" }
const _hoisted_48 = { class: "main-table__body-big-tr" }
const _hoisted_49 = { class: "main-table__body-big-tr" }
const _hoisted_50 = { class: "main-table__body-big-tr" }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = {
  key: 1,
  class: "main-table__body-big-tr-hover"
}
const _hoisted_53 = { key: 0 }
const _hoisted_54 = { class: "main-table__body-big-tr" }
const _hoisted_55 = {
  key: 2,
  class: "main-table__expiration-date"
}
const _hoisted_56 = { class: "student__container" }
const _hoisted_57 = { class: "student__data-range-wrap" }
const _hoisted_58 = { key: 0 }
const _hoisted_59 = { key: 0 }
const _hoisted_60 = {
  key: 1,
  class: "student__error"
}
const _hoisted_61 = {
  key: 0,
  class: "student__unsubscribe-button unsubscribeActive"
}
const _hoisted_62 = { key: 3 }
const _hoisted_63 = { class: "main-table__expiration-date" }
const _hoisted_64 = { class: "student__container" }
const _hoisted_65 = {
  key: 4,
  class: "main-table__mobile-wrap"
}
const _hoisted_66 = { class: "main-table__head" }
const _hoisted_67 = { class: "main-table__head-big-tr" }
const _hoisted_68 = { class: "main-table__sort-block" }
const _hoisted_69 = { class: "main-table__body-id" }
const _hoisted_70 = { class: "main-table__body-big-tr" }
const _hoisted_71 = { class: "student__popup-buttons" }
const _hoisted_72 = { class: "student__popup-buttons" }
const _hoisted_73 = { class: "student__popup-buttons" }
const _hoisted_74 = { class: "student__popup-buttons" }
const _hoisted_75 = { class: "student__popup-title student__popup-title-unsubscribe" }
const _hoisted_76 = { class: "student__popup-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhotoMock = _resolveComponent("PhotoMock")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_BlockUser = _resolveComponent("BlockUser")!
  const _component_UnblockUser = _resolveComponent("UnblockUser")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_SortArrow = _resolveComponent("SortArrow")!
  const _component_SelectArrow = _resolveComponent("SelectArrow")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_Select = _resolveComponent("Select")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/admin/students",
      class: "student__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          (this.studentData.image === null)
            ? (_openBlock(), _createBlock(_component_PhotoMock, {
                key: 0,
                class: "student__photo"
              }))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: this.studentData.image,
                class: "student__photo"
              }, null, 8, _hoisted_4))
        ]),
        _createElementVNode("div", _hoisted_5, [
          (this.studentData?.level?.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Level "))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, _toDisplayString(this.studentData?.level?.title), 1),
          (this.studentData?.skill?.skill_order)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Skill "))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, _toDisplayString(this.studentData?.skill?.skill_order), 1),
          (this.studentData?.skill?.skill_order)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, " Skill progress "))
            : _createCommentVNode("", true),
          (this.studentData?.skill)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createTextVNode(_toDisplayString(this.studentData?.skill?.confirmations?.length) + " ", 1),
                (this.studentData?.skill?.confirmations?.length === undefined)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, "0"))
                  : _createCommentVNode("", true),
                _cache[33] || (_cache[33] = _createTextVNode("/3 "))
              ]))
            : _createCommentVNode("", true),
          (this.studentData?.sports?.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, " Sport "))
            : _createCommentVNode("", true),
          (this.studentData?.sports?.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.studentData.sports, (sport) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: sport.id
                  }, _toDisplayString(sport?.name?.title), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (this.studentData?.schools?.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, " School "))
            : _createCommentVNode("", true),
          (this.studentData?.schools?.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.studentData.schools, (school) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: school.id
                  }, _toDisplayString(school?.title), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (this.studentData.instructors)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, " Instructor "))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.studentData.instructors, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "student__value-instrucrot",
              key: item.id
            }, _toDisplayString(item.full_name), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", null, [
            _cache[34] || (_cache[34] = _createElementVNode("div", { class: "student__title" }, " Full Name ", -1)),
            _createElementVNode("div", _hoisted_20, _toDisplayString(this.studentData.full_name), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[35] || (_cache[35] = _createElementVNode("div", { class: "student__title" }, " Email ", -1)),
            _createElementVNode("div", _hoisted_21, _toDisplayString(this.studentData.email), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[36] || (_cache[36] = _createElementVNode("div", { class: "student__title" }, " Phone Number ", -1)),
            _createElementVNode("div", _hoisted_22, _toDisplayString(this.studentData?.phone?.value), 1)
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", {
              class: "student__button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editstudent && _ctx.editstudent(...args)))
            }, [
              _createVNode(_component_Edit)
            ]),
            _createElementVNode("div", {
              class: "student__button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openBlockUserPopup && _ctx.openBlockUserPopup(...args)))
            }, [
              (!this.isStydentBanned)
                ? (_openBlock(), _createBlock(_component_BlockUser, { key: 0 }))
                : _createCommentVNode("", true),
              (this.isStydentBanned)
                ? (_openBlock(), _createBlock(_component_UnblockUser, { key: 1 }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              class: "student__button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openDeletePoup && _ctx.openDeletePoup(...args)))
            }, [
              _createVNode(_component_Delete)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: index,
              onClick: ($event: any) => (_ctx.activeTab = index),
              class: _normalizeClass({ 'activeTab': _ctx.activeTab === index })
            }, _toDisplayString(tab), 11, _hoisted_25))
          }), 128))
        ]),
        (_ctx.activeTab === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _createTextVNode(" Subscription status: " + _toDisplayString(this.studentData?.subscription?.status) + " ", 1),
                (this.studentData?.subscription === null)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_28, "Inactive"))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  _cache[37] || (_cache[37] = _createTextVNode(" ID ")),
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (this.getPaymentsList('?search=', 'id')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (this.getPaymentsList('?search=', '-id'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _cache[38] || (_cache[38] = _createTextVNode(" Payment date ")),
                  _createElementVNode("div", _hoisted_34, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (this.getPaymentsList('?search=', 'payment_date')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (this.getPaymentsList('?search=', '-payment_date'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _cache[39] || (_cache[39] = _createTextVNode(" Status ")),
                  _createElementVNode("div", _hoisted_36, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (this.getPaymentsList('?search=', 'status')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (this.getPaymentsList('?search=', '-status'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_37, [
                  _cache[40] || (_cache[40] = _createTextVNode(" Activation date ")),
                  _createElementVNode("div", _hoisted_38, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (this.getPaymentsList('?search=', 'payment_date')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (this.getPaymentsList('?search=', '-payment_date'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _cache[41] || (_cache[41] = _createTextVNode(" Deactivation date ")),
                  _createElementVNode("div", _hoisted_40, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (this.getPaymentsList('?search=', 'subscription_end_date')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (this.getPaymentsList('?search=', '-subscription_end_date'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _cache[42] || (_cache[42] = _createTextVNode(" Activated by ")),
                  _createElementVNode("div", _hoisted_42, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (this.getPaymentsList('?search=', 'payment_for')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (this.getPaymentsList('?search=', '-payment_for'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _cache[43] || (_cache[43] = _createTextVNode(" Type ")),
                  _createElementVNode("div", _hoisted_44, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (this.getPaymentsList('?search=', 'type')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[16] || (_cache[16] = ($event: any) => (this.getPaymentsList('?search=', '-type'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.anayticsData, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "main-table__body",
                  key: item,
                  onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.selectInstructor && _ctx.selectInstructor(...args)))
                }, [
                  _createElementVNode("div", _hoisted_45, _toDisplayString(item?.id), 1),
                  _createElementVNode("div", _hoisted_46, _toDisplayString(item?.payment_date?.split('T')[0]), 1),
                  _createElementVNode("div", _hoisted_47, _toDisplayString(item?.status), 1),
                  _createElementVNode("div", _hoisted_48, _toDisplayString(item?.payment_date?.split('T')[0]), 1),
                  _createElementVNode("div", _hoisted_49, _toDisplayString(item?.subscription_end_date?.split('T')[0]), 1),
                  _createElementVNode("div", _hoisted_50, [
                    _createTextVNode(_toDisplayString(item?.activated_by?.type) + " ", 1),
                    (item?.activated_by?.type === 'Admin')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_51, _toDisplayString(item?.activated_by?.email), 1))
                      : _createCommentVNode("", true),
                    (item?.activated_by?.type === 'Admin')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                          _createTextVNode(_toDisplayString(item?.activated_by?.type) + " ", 1),
                          (item?.activated_by?.type === 'Admin')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_53, _toDisplayString(item?.activated_by?.email), 1))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_54, _toDisplayString(item?.payment_for), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
              _cache[44] || (_cache[44] = _createElementVNode("div", { class: "main-table__expiration-date-title" }, " Expiration date ", -1)),
              _createElementVNode("div", _hoisted_56, [
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_57, [
                  _createElementVNode("div", {
                    class: "student__data-range",
                    onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendar && _ctx.toggleCalendar(...args)))
                  }, [
                    (this.selectedNewDate === null)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_58, _toDisplayString(this.selectedDatePlacholder), 1))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(this.selectedNewDate) + " ", 1),
                    _createVNode(_component_SelectArrow, {
                      class: _normalizeClass({ isOpenSelectArrow: _ctx.isCalendarVisible })
                    }, null, 8, ["class"])
                  ]),
                  _withDirectives(_createVNode(_component_Calendar, {
                    class: "student__calendar",
                    "select-date-fill": this.selectDateFill,
                    "selected-new-date": this.selectedNewDate
                  }, null, 8, ["select-date-fill", "selected-new-date"]), [
                    [_vShow, _ctx.isCalendarVisible]
                  ]),
                  (this.errorSubscribe)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                        _createVNode(_component_AlertBlock, {
                          message: this.errorSubscribe.expiration_date
                        }, null, 8, ["message"])
                      ]))
                    : _createCommentVNode("", true),
                  (this.errorMessageSubscribe)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_60, _toDisplayString(this.errorMessageSubscribe), 1))
                    : _createCommentVNode("", true)
                ])), [
                  [_directive_click_outside, _ctx.closeCalendar]
                ]),
                _createElementVNode("div", {
                  class: "student__active-subscription-button",
                  onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.subscribe && _ctx.subscribe(...args)))
                }, " Activate subscription ")
              ]),
              (this.studentData.subscription === null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_61, "Unsubscribe"))
                : _createCommentVNode("", true),
              (this.studentData.subscription !== null)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "student__unsubscribe-button",
                    onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.openPopupUnsibscribe && _ctx.openPopupUnsibscribe(...args)))
                  }, "Unsubscribe"))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
              _createElementVNode("div", _hoisted_63, [
                _cache[45] || (_cache[45] = _createElementVNode("div", { class: "main-table__expiration-date-title" }, " Period ", -1)),
                _createElementVNode("div", _hoisted_64, [
                  _createVNode(_component_Select, {
                    data: _ctx.dataPeriod,
                    "select-name": "Month",
                    class: "student__select",
                    title: 'title',
                    onOptionSelected: _ctx.handleOptionSelected
                  }, null, 8, ["data", "onOptionSelected"]),
                  _createElementVNode("div", {
                    class: "student__active-subscription-button",
                    onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.refunds && _ctx.refunds(...args)))
                  }, " Issue a return ")
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
              _createElementVNode("div", _hoisted_66, [
                _cache[47] || (_cache[47] = _createElementVNode("div", { class: "main-table__head-id" }, " ID ", -1)),
                _createElementVNode("div", _hoisted_67, [
                  _cache[46] || (_cache[46] = _createTextVNode(" Refunds date ")),
                  _createElementVNode("div", _hoisted_68, [
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[22] || (_cache[22] = ($event: any) => (this.refundsList('?search=', 'payment_date')))
                    }),
                    _createVNode(_component_SortArrow, {
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (this.refundsList('?search=', '-payment_date'))),
                      class: "main-table__sort-bottom"
                    })
                  ])
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refundsData, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "main-table__body",
                  key: item,
                  onClick: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.selectInstructor && _ctx.selectInstructor(...args)))
                }, [
                  _createElementVNode("div", _hoisted_69, _toDisplayString(item?.id), 1),
                  _createElementVNode("div", _hoisted_70, _toDisplayString(item?.payment_date?.split('T')[0]), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[48] || (_cache[48] = _createElementVNode("div", { class: "student__popup-title" }, " Are you sure? ", -1)),
            _createElementVNode("div", _hoisted_71, [
              _createElementVNode("div", {
                class: "student__popup-button-yes",
                onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.deleteStudent && _ctx.deleteStudent(...args)))
              }, " Yes "),
              _createElementVNode("div", {
                class: "student__popup-button-no",
                onClick: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalBlockUser)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 1,
          "on-close": _ctx.handleCloseModalBlockUser
        }, {
          default: _withCtx(() => [
            _cache[49] || (_cache[49] = _createElementVNode("div", { class: "student__popup-title" }, " Are you sure? ", -1)),
            _createElementVNode("div", _hoisted_72, [
              (!this.isStydentBanned)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "student__popup-button-yes",
                    onClick: _cache[27] || (_cache[27] = 
//@ts-ignore
(...args) => (_ctx.blockStudent && _ctx.blockStudent(...args)))
                  }, " Yes "))
                : _createCommentVNode("", true),
              (this.isStydentBanned)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "student__popup-button-yes",
                    onClick: _cache[28] || (_cache[28] = 
//@ts-ignore
(...args) => (_ctx.unblockStudent && _ctx.unblockStudent(...args)))
                  }, " Yes "))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "student__popup-button-no",
                onClick: _cache[29] || (_cache[29] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalBlockUser && _ctx.handleCloseModalBlockUser(...args)))
              }, " No ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalRefunds)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 2,
          "on-close": _ctx.handleCloseModalRefunds
        }, {
          default: _withCtx(() => [
            _cache[50] || (_cache[50] = _createElementVNode("div", { class: "student__popup-title" }, " Refund processed successfully ", -1)),
            _createElementVNode("div", _hoisted_73, [
              _createElementVNode("div", {
                class: "student__popup-button-yes",
                onClick: _cache[30] || (_cache[30] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalRefunds && _ctx.handleCloseModalRefunds(...args)))
              }, " Ok ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalSubscribed)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 3,
          "on-close": _ctx.handleCloseModalSubscribed
        }, {
          default: _withCtx(() => [
            _cache[51] || (_cache[51] = _createElementVNode("div", { class: "student__popup-title" }, " You have successfully subscribed ", -1)),
            _createElementVNode("div", _hoisted_74, [
              _createElementVNode("div", {
                class: "student__popup-button-yes",
                onClick: _cache[31] || (_cache[31] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalSubscribed && _ctx.handleCloseModalSubscribed(...args)))
              }, " Ok ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isPopupUnsibscribe)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 4,
          "on-close": _ctx.handleCloseModalUnsubscribed
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_75, [
              _createTextVNode(" The subscription is valid until to " + _toDisplayString(this.selectedDatePlacholder) + " ", 1),
              _cache[52] || (_cache[52] = _createElementVNode("br", null, null, -1)),
              _cache[53] || (_cache[53] = _createTextVNode(" Then you will be unsubscribed "))
            ]),
            _createElementVNode("div", _hoisted_76, [
              _createElementVNode("div", {
                class: "student__popup-button-yes",
                onClick: _cache[32] || (_cache[32] = 
//@ts-ignore
(...args) => (_ctx.unsubscribe && _ctx.unsubscribe(...args)))
              }, " Ok ")
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}